h1 {
    color: #333;
}

p {
    color: #666;
    line-height: 1.6;
}

.container {
    margin: 0 auto;
    /*max-width: 800px;*/
    padding: 20px;
}